import React from 'react'

const AddCropSection = ({ handleAddCropDrawer }) => {
    return (
        <div className="cursor-pointer rounded bg-white p-3"
            onClick={handleAddCropDrawer}
        >
            <div className="flex items-center justify-between gap-2">
                <img src="webp/field.webp" alt="crop" className="rounded-full w-24 h-24 aspect-square" />
                <div className='text-right'>
                    <h4 className="title mb-4">میری فصلیں</h4>

                    <p className="description leading-9">
                        آپ کی اضافی فصلیں یہاں درج کی جائیں گی۔
                    </p>
                </div>
            </div>
            <button className="bg-[#007F3B] flex flex-row-reverse items-center justify-center gap-2 rounded w-full mt-3 pt-3 pb-3 text-white">
                <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_3035_4617)">
                        <path
                            d="M7.7 12H9.3V8.8H12.5V7.2H9.3V4H7.7V7.2H4.5V8.8H7.7V12ZM8.5 16C7.39333 16 6.35333 15.7899 5.38 15.3696C4.40667 14.9499 3.56 14.38 2.84 13.66C2.12 12.94 1.55013 12.0933 1.1304 11.12C0.710133 10.1467 0.5 9.10667 0.5 8C0.5 6.89333 0.710133 5.85333 1.1304 4.88C1.55013 3.90667 2.12 3.06 2.84 2.34C3.56 1.62 4.40667 1.04987 5.38 0.6296C6.35333 0.209867 7.39333 0 8.5 0C9.60667 0 10.6467 0.209867 11.62 0.6296C12.5933 1.04987 13.44 1.62 14.16 2.34C14.88 3.06 15.4499 3.90667 15.8696 4.88C16.2899 5.85333 16.5 6.89333 16.5 8C16.5 9.10667 16.2899 10.1467 15.8696 11.12C15.4499 12.0933 14.88 12.94 14.16 13.66C13.44 14.38 12.5933 14.9499 11.62 15.3696C10.6467 15.7899 9.60667 16 8.5 16ZM8.5 14.4C10.2867 14.4 11.8 13.78 13.04 12.54C14.28 11.3 14.9 9.78667 14.9 8C14.9 6.21333 14.28 4.7 13.04 3.46C11.8 2.22 10.2867 1.6 8.5 1.6C6.71333 1.6 5.2 2.22 3.96 3.46C2.72 4.7 2.1 6.21333 2.1 8C2.1 9.78667 2.72 11.3 3.96 12.54C5.2 13.78 6.71333 14.4 8.5 14.4Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_3035_4617">
                            <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>
                فصل شامل کریں
            </button>
        </div>
    )
}

export default AddCropSection