import React, { createContext, useState, useContext, useLayoutEffect } from 'react';
import { API, authentication, subscribeUser } from '../API';
import { useDispatch } from 'react-redux';
import { getSubscriberDetails, setSubcriberData } from '../pages/Subscription/store';
import { addMsisdn } from '../redux/auth';
import SplashScreen from '../components/SplashScreen';
import subscriber from '../utils/subscriber.json'

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const loadingState = (loading) => setLoading(loading)

    const timeout = 2200;

    const checkSubscriber = async (data) => {
        if (data) {
            let msisdn = data.msisdn;
            dispatch(addMsisdn({ msisdn }))
            sessionStorage.setItem('authToken', data.accessToken);
            API.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
            const subRes = await dispatch(getSubscriberDetails({ cellno: msisdn })).unwrap();
            // console.log("subscriber res ", subRes)
            if (subRes.message === "Subscriber not found.") {
                let data = {
                    subMode: "WEBVIEW",
                    msisdn: msisdn,
                }
                subscribeUser(data)
                    .then(async (res) => {
                        // console.log("Subscription res ", res.data)
                        const subRes = await dispatch(getSubscriberDetails({ cellno: msisdn })).unwrap();
                        // console.log("Subscriber res ", subRes)
                        setTimeout(() => {
                            setLoading(false);
                        }, timeout);
                    })
                    .catch((err) => {
                        console.log("Subscription Error: ", err)
                        sessionStorage.removeItem("authToken");
                        localStorage.removeItem("persist:root");
                    })
                return;
            }
            setTimeout(() => {
                setLoading(false);
            }, timeout);
            return;
        }

        authentication("", process.env.REACT_APP_DUMMY_NUMBER)
            .then(async (res) => {
                dispatch(addMsisdn({ msisdn: res.data.data.msisdn }))
                dispatch(setSubcriberData(subscriber))
                sessionStorage.setItem('authToken', res.data.data.accessToken);
                API.defaults.headers.common['Authorization'] = `Bearer ${res.data.data.accessToken}`;
                setTimeout(() => {
                    setLoading(false);
                }, timeout);
            })
            .catch((err) => {
                console.log("err ", err)
                // setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                }, timeout);
            })
        // dispatch(setSubcriberData(subscriber))
        setTimeout(() => {
            setLoading(false);
        }, timeout);
    }

    useLayoutEffect(() => {
        // extract value after tpPayload= from url
        const token = sessionStorage.getItem('authToken');
        if (token) {
            API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setTimeout(() => {
                setLoading(false);
            }, timeout);
            return;
        }
        const payload = new URL(window.location.href).searchParams.get('tp_payload');
        if (!payload) {
            const cellNo = new URL(window.location.href).searchParams.get('msisdn');
            const token = new URL(window.location.href).searchParams.get('tpToken');
            if (!cellNo || !token) {
                window.location.replace(process.env.REACT_APP_HE_URL)
                return;
            }
            if (cellNo && cellNo !== "no" && token && token !== "no") {
                checkSubscriber({
                    msisdn: cellNo,
                    accessToken: token
                })
                return;
            }
            checkSubscriber(null)
            return;
        }
        authentication(payload)
            .then(async (res) => {
                // console.log("Auth res ", res.data.data)
                checkSubscriber(res.data.data)
            })
            .catch((err) => {
                console.log("err ", err)
                // setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                }, timeout);
            })
    }, [])

    const value = {
        loading,
        loadingState
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading ? children : <SplashScreen />}
        </AuthContext.Provider>
    );
};