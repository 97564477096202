import React, { useEffect, useState } from 'react'
import Prediction from './Prediction';
import Minimum from './Minimum';
import Maximum from './Maximum';
import { updateSubscriber } from '../../../API';
import { getSubscriberDetails } from '../../Subscription/store';
import { useDispatch, useSelector } from 'react-redux';

const UserOnboarding = () => {
    const [loading, setLoading] = useState(false)
    const [running, setRunning] = useState(true)
    const dispatch = useDispatch();
    const authStore = useSelector((state) => state.auth);
    const steps = {
        "Prediction": {
            "text": "پیشن گوئی",
            "component": <Prediction />,
            "definition": "ہم ماضی کی قیمتوں کی بنیاد پر فصل کی  شرح کی پیشن گوئی کر سکتے ہیں۔"
        },
        "Minimum": {
            "text": "کم سے کم",
            "component": <Minimum />,
            "definition": "فصل کی کم از کم قیمت ہو سکتی ہے۔"
        },
        "Maximum": {
            "text": "زیادہ سے زیادہ",
            "component": <Maximum />,
            "definition": "فصل کی زیادہ سے زیادہ قیمت ہو سکتی  ہے۔"
        }
    }
    const [step, setStep] = useState(0)

    // ===================== | Disable body scroll when onboarding is running | ================== //
    useEffect(() => {
        if (running) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => (document.body.style.overflow = '');
    }, [running]);

    const completeDemo = () => {
        setLoading(true)
        let data = {
            msisdn: authStore?.authData?.msisdn,
            mandiDemo: true
        }
        if (authStore?.authData?.msisdn === process.env.REACT_APP_DUMMY_NUMBER) {
            setRunning(false);
            setLoading(false);
            return;
        }
        updateSubscriber(data)
            .then(res => {
                dispatch(getSubscriberDetails({ cellno: authStore?.authData?.msisdn }));
                if (res.data.success) setRunning(false)
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
    }

    if (!running) return null
    return (
        <div className='absolute top-0 z-[99999] w-full h-screen backdrop-brightness-[.2] backdrop-blur-[3px] flex flex-col justify-center items-center'>
            <p className='text-white'><span className='digit'>{step + 1}/3 </span>مرحلہ</p>
            <div className='w-[90%]'>
                {steps[Object.keys(steps)[step]].component}
            </div>
            <div className={`w-[90%] flex ${step === 0 ? "px-16 justify-start" : step === 1 ? "justify-center" : "px-12 justify-end"} `}>
                <img src="/svg/demo-arrow.svg" alt="arrow" className='my-4 w-7 h-32' />
            </div>
            <div className='w-[85%] bg-[#FFD705] px-2 rounded-lg flex justify-center items-center h-[100px]'>
                <p className='text-right text-[16px] leading-10'>
                    <span className='text-[16px] font-bold font-[CustomNotoNastaliqFont]'> {steps[Object.keys(steps)[step]].text}:&nbsp; </span>{steps[Object.keys(steps)[step]].definition}
                </p>
            </div>
            <div className='flex w-[85%] gap-3'>
                {step + 1 !== 3 && <button
                    disabled={loading}
                    className={`h-12 flex-1 text-[16px] font-bold  px-4 py-2 rounded-md mt-4 border ${loading ? "border-gray-500 text-gray-500" : "border-white text-white active:translate-y-[1px]"}`}
                    onClick={completeDemo}
                >سکئپ</button>}
                <button
                    disabled={loading}
                    className={`h-12 flex-1 bg-[#546F1C] text-[16px] font-bold text-white px-4 py-2 rounded-md mt-4 ${loading ? "bg-gray-500" : "bg-[#546F1C] active:translate-y-[1px]"}`}
                    onClick={() => {
                        if (step < 2) setStep(step + 1)
                        else completeDemo();
                    }}
                >آگے</button>
            </div>
        </div>
    )
}

export default UserOnboarding