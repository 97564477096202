import "./Home.css";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import LocationsModal from "../../components/Modals/LocationsModal/LocationsModal";
import GenericModal from "../../components/Modals/GenericModal/GenericModal";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getWeatherData,
  getSitesData,
  updateSitesData,
  setTodayWeather,
  getPunjabiMonth,
  updateChangeLocationModal,
} from "./store";
import { getSubscriberDetails, updateSubscriberLocation } from "../Subscription/store";
import { getAllVideos } from "../Videos/store";
import { findTodayWeather, findWeatherForCurrentHour, formatDateWithUrduMonth, getPunjabiDate } from "../../utils/extras";
import { Modal, Spinner } from "react-bootstrap";
import { applyInterFontToDigits } from "../CropDetails/CropDetails";
import { useDebouncedValue } from "@mantine/hooks";
import WeatherModal from "./components/WeatherModal";
import ChangeLocationModal from "./components/ChangeLocationModal";
import VideosSection from "./components/MainVideosSection";
import CropSection from "./CropSection";
import ReactGA from "react-ga4";

const Home = () => {
  const weatherBaseURL = process.env.REACT_APP_WEATHER_BASE_URL_DEVELOPMENT;
  const dispatch = useDispatch();
  const cropStore = useSelector((state) => state.CropsStore);
  const homeCropStore = useSelector((state) => state.Crops);
  const WeatherData = useSelector((state) => state.Crops?.weatherData?.data);
  const authStore = useSelector((state) => state.auth);
  const subscriptionStore = useSelector((state) => state.Subscription);
  const [LocationConfirmationModal, setLocationConfirmationModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [debounced] = useDebouncedValue(searchText, 500);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [currentWeather, setCurrentWeather] = useState(null);
  const [currentDayWeather, setCurrentDayWeather] = useState(null);
  const [isLocationUpdated, setIsLocationUpdated] = useState(false);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState(false)
  const [punjabiDate, setPunjabiDate] = useState("")

  useEffect(() => {
    let punjabiDate = getPunjabiDate();
    setPunjabiDate(punjabiDate);
    dispatch(getSitesData({ searchText, limit, offset }));
  }, [debounced]);

  // Handle search text change
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setOffset(0);
  };

  const navigate = useNavigate();
  const handleClickLocationModal = (value) => {
    dispatch(updateChangeLocationModal(value));
  };

  const cellNo = authStore?.authData?.msisdn;
  useEffect(() => {
    dispatch(getWeatherData({ lat: subscriptionStore?.subscriberDetails?.data?.lat, lng: subscriptionStore?.subscriberDetails?.data?.lng }));
  }, [dispatch, subscriptionStore?.subscriberDetails?.data?.lat, subscriptionStore?.subscriberDetails?.data?.lng]);

  useEffect(() => {
    const currDate = moment().format("YYYY-MM-DD");
    const weather = cropStore?.weatherData?.data?.weekly_weather?.find((item) => moment(item?.Date).format("YYYY-MM-DD") === currDate) || {};
    dispatch(setTodayWeather(weather))
  }, [dispatch, cropStore?.weatherData?.data?.weekly_weather])

  const handleUpdateLocation = () => {
    cellNo !== process.env.REACT_APP_DUMMY_NUMBER ?
      dispatch(updateSitesData({ locationObj: homeCropStore?.locationObject }))
      :
      dispatch(updateSubscriberLocation(homeCropStore?.locationObject))
    setSearchText("");
    setIsLocationUpdated(true)
    setLocationConfirmationModal(true);
    handleClickLocationModal(false);

    // Custom event for location change also add current location and changed location
    ReactGA.event("Location Changed",{
      label: "Location Changed",
      action:"click",
      category: "location",
      previous_location: subscriptionStore?.subscriberDetails?.data?.tehsilUrdu || null,
      changed_location: homeCropStore?.locationObject?.tehsilUrdu || null,
      user_msisdn: authStore?.authData?.msisdn || null,
    });
  }

  useEffect(() => {
    async function getSubDetails() {
      if (authStore?.authData) {
        if (authStore?.authData?.msisdn === process.env.REACT_APP_DUMMY_NUMBER) return
        const result = await dispatch(getSubscriberDetails({ cellno: authStore?.authData?.msisdn })).unwrap();
        if (result?.isSubscriber === "N") {
          navigate("/subscription");
        }
      }
    }
    getSubDetails();
  }, [dispatch, authStore?.authData, navigate, isLocationUpdated]);

  useEffect(() => {
    if (isLocationUpdated) {
      setIsLocationUpdated(false);
    }
  }, [isLocationUpdated]);

  useEffect(() => {
    dispatch(getPunjabiMonth());
    dispatch(getAllVideos())
  }, [dispatch]);

  useEffect(() => {
    if (!WeatherData) return;
    const weatherAtCurrentHour = findWeatherForCurrentHour(WeatherData?.hourlyWeather?.HourlyForecasts);
    const weatherAtCurrentCurrentDay = findTodayWeather(WeatherData?.weeklyWeather);
    setCurrentDayWeather(weatherAtCurrentCurrentDay);
    setCurrentWeather(weatherAtCurrentHour);
  }, [WeatherData?.hourlyWeather?.HourlyForecasts, WeatherData?.weeklyWeather]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
    // Custom event for weather data
    ReactGA.event("Weather Served",{
      label: "Weather Served",
      action:"click",
      category: "weather",
      location: subscriptionStore?.subscriberDetails?.data?.tehsilUrdu || null,
      weather: currentWeather?.Temperature?.Value ? `${Math.round(currentWeather?.Temperature?.Value)} °C` : null,
      user_msisdn: authStore?.authData?.msisdn || null,
    });
  };
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="container" id="crops-page">
        {/* Location Section */}
        <>
          {
            !subscriptionStore?.isSubscriptionLoading ?
              <div className="flex items-center justify-between rounded bg-white locations-container mt-3">
                <button
                  onClick={() => handleClickLocationModal(true)}
                  className="location-container-btn"
                >
                  مقام تبدیل کریں
                </button>
                <div className="flex items-center">
                  <p className="m-0">{subscriptionStore?.subscriberDetails?.data?.tehsilUrdu}</p>
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3011_4582)">
                      <path
                        d="M9.49998 1.58301C6.43623 1.58301 3.95831 4.06092 3.95831 7.12467C3.95831 11.2809 9.49998 17.4163 9.49998 17.4163C9.49998 17.4163 15.0416 11.2809 15.0416 7.12467C15.0416 4.06092 12.5637 1.58301 9.49998 1.58301ZM9.49998 9.10384C8.40748 9.10384 7.52081 8.21717 7.52081 7.12467C7.52081 6.03217 8.40748 5.14551 9.49998 5.14551C10.5925 5.14551 11.4791 6.03217 11.4791 7.12467C11.4791 8.21717 10.5925 9.10384 9.49998 9.10384Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3011_4582">
                        <rect width="19" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              :
              <div className="flex justify-center items-center rounded bg-white h-16 mt-3">
                <Spinner />
              </div>
          }
        </>

        {/* Weather Section */}
        <>
          {
            currentWeather ?
              <div className="weather-information cursor-pointer rounded bg-white mt-3"
                onClick={openModal}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center left-side w-1/4">
                    <div className="marquee-container">
                      <div className="marquee-content">
                        <p><span className="text-[14px]" dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(punjabiDate) }} /> {homeCropStore?.punjabiMonth?.monthPunjabi}</p>
                      </div>
                    </div>
                  </div>
                  <div className="right-side">
                    <div className="flex items-center">
                      <p className="temperature">
                        <span className="digit">{Math.round(currentWeather?.Temperature?.Value) || "36"} °C</span>
                      </p>
                      {weatherBaseURL && currentWeather?.WeatherIcon ?
                        <img
                          className="w-12 h-12 rounded-full p-1"
                          src={`${weatherBaseURL}/${currentWeather?.WeatherIcon < 10 ? `0${currentWeather?.WeatherIcon}` : currentWeather?.WeatherIcon}.svg`}
                          alt="weather-icon"
                        /> :
                        <img
                          className="w-12 h-12 bg-gray-100 rounded-full p-1"
                          src={'png/cloud.png'}
                          alt="weather-icon"
                        />}
                    </div>

                    {currentWeather?.DateTime && <p className="date" dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(formatDateWithUrduMonth(currentWeather.DateTime)) }} />}
                  </div>
                </div>
                <button className="card-btn tracking-wider">
                  <svg
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.8334 5.33366H2.72002L6.44669 1.60699L5.50002 0.666992L0.166687 6.00033L5.50002 11.3337L6.44002 10.3937L2.72002 6.66699H10.8334V5.33366Z"
                      fill="white"
                    />
                  </svg>
                  موسم کی پیشن گوئی دیکھیں
                </button>
              </div> :
              <div className="flex justify-center items-center rounded bg-white h-36 mt-3">
                <Spinner />
              </div>
          }
        </>

        {/* Crops Section */}
        <CropSection
          currentWeather={currentWeather}
          handleClickLocationModal={handleClickLocationModal}
        />

        {/* Ad Placement */}
        {/* <img src="svg/ad-placement-compressed.svg" alt="ad" className="mt-3 w-full aspect-auto" /> */}

        {/* Videos Section */}
        <div className="videos-section">
          <div className="header flex items-center justify-between gap-3">
            <Link to="/videos" className="header-btn">
              <svg
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8334 5.33366H2.72002L6.44669 1.60699L5.50002 0.666992L0.166687 6.00033L5.50002 11.3337L6.44002 10.3937L2.72002 6.66699H10.8334V5.33366Z"
                  fill="white"
                />
              </svg>
              مزید دیکھیں
            </Link>
            <h4 className="header-title">ویڈیوز</h4>
          </div>

          <div className="videos-container">
            <VideosSection crop={cropStore.subscriberCrops?.[0]?.cropId} />
          </div>
        </div>
      </div>

      {/* Weather Modal Start */}
      <GenericModal isOpen={isModalOpen} onClose={closeModal}>
        <WeatherModal
          weatherData={homeCropStore?.weatherData?.data}
          closeModal={closeModal}
          currentWeather={currentWeather}
          currentDayWeather={currentDayWeather}
        />
      </GenericModal >
      {/* Weather Modal End */}

      {/* Location Modal Start */}
      <GenericModal
        isOpen={homeCropStore.isChangeLocationModal}
        onClose={() => handleClickLocationModal(false)}
      >
        <ChangeLocationModal
          handleSearchChange={handleSearchChange}
          handleClickLocationModal={handleClickLocationModal}
          handleUpdateLocation={handleUpdateLocation}
          searchText={searchText}
        />
      </GenericModal >
      {/* Location Modal End */}

      {/* Subscription Dialog */}
      <Modal
        show={isSubscriptionDialogOpen}
        onClose={() => setIsSubscriptionDialogOpen(false)}
        onHide={() => setIsSubscriptionDialogOpen(false)}
        centered
        className="confirmations-modal"
      >
        <Modal.Body>
          <div className="flex flex-col items-center">
            <p className="description leading-[45px] tracking-wide max-w-96">مزید فصلیں شامل کرنے کے لیے آپ کو سبسکرائب کرنا ہوگا۔</p>
            <button className="w-100 modal-btn max-w-96" onClick={() => navigate('/subscription')}>ٹھیک ہے</button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Subscription Dialog */}

      <LocationsModal
        show={LocationConfirmationModal}
        onHide={() => { setLocationConfirmationModal(false) }}
      />
    </>
  );
};

export default Home;