import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { setMandi, setMandiModal } from '../store';
import { useDebouncedValue } from '@mantine/hooks';

const MandiModal = ({ cancel }) => {
    const mandiStore = useSelector((state) => state.MandiRates);
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");
    const [debounced] = useDebouncedValue(searchText, 500);
    const [tempMandi, setTempMandi] = useState(mandiStore.selectedMandi || null);
    return (
        <>
            <Modal.Header>
                <Modal.Title className='w-full'>
                    <div className='flex justify-between'>
                        <img src="/svg/close.svg" alt="close" className='cursor-pointer' onClick={cancel} />
                        <div>منڈی منتخب کریں</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='relative'>
                    <input
                        type="text"
                        className="w-full h-11 border-none rounded-full text-right pr-10 bg-gray-200 text-sm focus:border-none active:outline-none focus:outline-none"
                        placeholder="تلاش کریں"
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <img src="/svg/magnifier.svg" alt="search" className='absolute top-[10px] right-3' />
                    {/* <img src="/svg/recorder.svg" alt="search" className='absolute top-[6px] left-3' /> */}
                </div>

                <div className='w-full list-none h-96 overflow-y-auto my-1'>
                    {
                        mandiStore.mandis.length === 0 ? <div className='text-center mt-3'>کوئی منڈی نہیں ملی</div> :
                            mandiStore.mandis.filter((item) => item[1].includes(debounced)).map((item, index) => (
                                <div
                                    key={index}
                                    className={`${tempMandi[0] === item[0] && "text-[#546F1C]"} flex justify-end gap-2 items-center cursor-pointer text-right text-[16px] py-[12px] w-full border-b border-gray-300 pr-2`}
                                    onClick={() => setTempMandi(item)}
                                >
                                    <div>{item[1]}</div>
                                    {tempMandi[0] === item[0] && <img src="svg/green-tick.svg" alt="arrow" className='w-4 h-4' />}
                                </div>
                            ))
                    }
                </div>

                <div className='flex gap-3'>
                    <button
                        onClick={cancel}
                        className="border-2 border-[#546F1C] text-[#546F1C] rounded w-full h-10 active:translate-y-[1px]"
                    >
                        منسوخ کریں
                    </button>
                    <button
                        disabled={!mandiStore.selectedMandi}
                        onClick={() => {
                            dispatch(setMandi(tempMandi));
                            dispatch(setMandiModal(false))
                        }}
                        className={`text-white rounded w-full h-10 ${!mandiStore.selectedMandi ? "cursor-not-allowed bg-gray-400" : "active:translate-y-[1px] bg-[#546F1C]"}`}
                    >
                        منتخب کریں
                    </button>
                </div>
            </Modal.Body>
        </>
    )
}

export default MandiModal