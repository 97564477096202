import React from 'react'
import "./index.css"

const SplashScreen = () => {
    return (
        <div className='flex justify-center h-screen'>
            <div className='max-w-[480px] relative w-full bg-[#f8fbea]'>
                <div className="logo-container">
                    <img
                        src='webp/kz-logo-compressed.webp'
                        alt='kz-logo'
                        className='z-[0]'
                    />
                </div>

                <img
                    src='webp/splash-img.webp'
                    alt='splash-img'
                    className='absolute bottom-0 w-full fadeIn z-[99999999]'
                />
            </div>
        </div>
    )
}

export default SplashScreen