import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';
import { applyInterFontToDigits } from '../../CropDetails/CropDetails';

const points = [
    "آپ تمام فصلیں شامل کر سکتے ہیں۔",
    "آپ موسم سے متعلق تمام معلومات حاصل کر سکتے ہیں۔",
    "آپ فصل سے متعلق تمام ویڈیوز دیکھ سکتے ہیں۔"
    // "منڈی جو آپ کو آپ کی منڈی کے قریب کے مقابلے میں زیادہ منافع دے سکتی ہے۔",
    // "نقل و حمل کی لاگت کی تصدیق کرنے کے لئے",
    // "تازہ ترین قیمتوں کا اشتراک کریں گے۔"
]

const PremiumPlan = ({onClick}) => {
    const subsStore = useSelector(state => state.Subscription)
    return (
        <div className="flex flex-col items-center border rounded-md w-full p-4">
            <img src="svg/star.svg" alt='star' width={70} />
            <h2 className="text-center mt-4 text-[18px] font-bold">اب آپ پریمیم صارف ہیں</h2>
            <h2 className="text-center text-[#777777] mt-3 text-[14px] font-bold">!سبسکرائب کرنے کے لیے آپ کا شکریہ</h2>
            <div className="mt-3">
                {points.map((point, index) => (
                    <div key={index} className="flex items-start justify-end gap-2">
                        <p className="text-[14px] text-right leading-8">{point}</p>
                        <img src="svg/green-rounded-tick.svg" className="mt-2" alt="check" width={16} />
                    </div>
                ))}
            </div>
            <p className='text-right flex justify-center items-start m-0 w-full bg-[#FFEFBC] p-2 rounded-md leading-8'>
                <p>
                    <b>ڈس کلیمر:&nbsp;&nbsp;</b>
                    تمام خصوصیات اگلے دن کی بلنگ یعنی &nbsp;<span className='text-[14px]' dangerouslySetInnerHTML={{__html: applyInterFontToDigits(moment(subsStore?.subscriberDetails?.data?.nextChargeDt).format("YYYY-MM-DD"))}}></span>&nbsp; تک حاصل کر سکتے ہیں۔
                </p>
                <img src='svg/yellow-info.svg' alt='error' width={16} className='mt-2 ml-2' />
            </p>
            <button onClick={onClick} className="no-underline mt-4 text-white bg-[#007F3B] w-full h-[54px] rounded-[4px] flex justify-center items-center text-[18px]">واپس جائیں</button>
        </div>
    )
}

export default PremiumPlan