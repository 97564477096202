import React from 'react'
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const navigate = useNavigate();
    return (
        <div className='h-[80vh] flex flex-col justify-center items-center'>
            <div className='flex flex-col items-center w-[65%]'>
                <img src='svg/error.svg' alt='error' className='w-[124px] mx-auto' />
                <div className='font-bold text-[24px]'>404</div>
                <p className='text-[16px] mt-2'>یہ صفحہ اس وقت دستیاب نہیں ہے</p>
                <button
                    className='active:translate-y-[1px] bg-black text-white font-bold rounded-[4px] flex justify-center items-center w-full h-[54px] mt-2'
                    onClick={() => navigate('/')}
                >واپس جایں</button>
            </div>
        </div>
    )
}

export default ErrorPage